import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserModel, IUserData, IUserModelDTO } from './model'

const defaultUserState = {
  authorized: false,
  id: null,
  role: null,
  username: null,
  token: null
}

const initialState: IUserModel = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : defaultUserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<IUserModelDTO>) => {
      const newState = {
        ...state,
        ...action.payload.user,
        token: action.payload.jwt,
        authorized: true
      }

      localStorage.setItem('user', JSON.stringify(newState))
      localStorage.setItem('token', newState.token as string)

      return newState
    },
    logout: (state) => {
      localStorage.clear()

      return {
        ...state,
        ...defaultUserState
      }
    }
  },
})

const { actions, reducer } = userSlice

export const { login, logout } = actions
export { reducer as userReducer }